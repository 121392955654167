export class phoneNr {
    constructor(phone) {
        this.phone = phone.replaceAll(` `, ``)
    }

    getNr() {
        return `<div class="tscCotactObj"><span>${this.phone}</span></div>`
    }

    onClick() {
        let a = document.createElement('a')
        a.href = `callto:${this.phone}`
        document.body.appendChild(a)
        a.click()
        a.remove()
    }
}