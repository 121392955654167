<template>
    <div class="btn-wrap" :class="btype">
        <div class="btn-text">{{ text }}</div>
        <IconComponent v-if="hasIcon" :icon="icon.icon" :size="icon.size" :strokeWidth="icon.strokeWidth"
            :strokeColor="icon.strokeColor" :fillColor="icon.fillColor"></IconComponent>
    </div>
</template>
<script>
import IconComponent from './IconComponent.vue';

export default {
    name: `ButtonComponent`,
    props: {
        btype: String,
        icon: {
            type: Object,
            default: () => { return { size: `1.5rem`, strokeColor: `#FFFFFF` } }
        },
        txt: String,
        finalTxt: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            hasIcon: typeof this.icon !== `undefined` && this.icon.icon && this.icon.icon !== `` ? true : false,
            text: !this.finalTxt ? this.$t(this.txt) : this.txt,
            clr: this.$colors.main,
        }
    },
    components: { IconComponent }
}
</script>
<style scoped>
.btn-wrap {
    display: inline-flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 0.3rem;
    font-size: 1.1rem;
    font-weight: 500;
    text-transform: uppercase;
    box-sizing: border-box;
    color: v-bind(clr);
    border: 2px solid v-bind(clr);
    -moz-user-select: none;
    -ms-user-select: none;
    -webkit-user-select: none;
    user-select: none;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    box-shadow: none;
    -webkit-box-shadow: none;
    margin: 0.5rem 0;
}

.btn-wrap .btn-text,
.btn-wrap .icon-wr {
    margin: 0 0.7rem;
    letter-spacing: 2px;
}

.btn-wrap.left {
    flex-direction: row-reverse;
}

.btn-wrap.main {
    background-color: v-bind(clr);
    color: white !important;
}

.btn-wrap:hover {
    /*box-shadow: 0px 0px 15px #e3e3e3;*/
    box-shadow:5px 5px 0px #e3e3e3 !important;
    -webkit-box-shadow: 5px 5px 0px #e3e3e3 !important;
}
</style>