<template>
    <div :class="{ tscOpenLng: isOpen }" class="lSwitch">
        <div class="aLng" @click="handleLang">{{ actLng }}</div>
        <div class="iLng" @click="changeLang">{{ inactLng }}</div>
    </div>
</template>
<script>
export default {
    name: `LangSwitch`,
    data() {
        return {
            cookie: this.$getCookie(`tscLang`),
            locales: this.$i18n.availableLocales,
            inactLng: null,
            actLng: this.$i18n.locale,
            isOpen: false,
            aClr: null
        }
    },
    mounted() {
        this.aClr = this.$isMobile().mobile ? `white` : this.$colors.main
        let lng = this.$route.params.lang
        this.locales.map(l => {
            if (l !== this.actLng) {
                this.inactLng = l
            }
        })
        if (this.actLng !== this.cookie && this.cookie !== false || (this.locales.indexOf(lng) > -1 && this.actLng !== lng)) {
            this.changeLang()
        }
    },
    methods: {
        handleLang() {
            this.isOpen = !this.isOpen
        },
        changeLang() {
            this.$root.$i18n.locale = this.inactLng
            this.inactLng = this.actLng
            this.actLng = this.$i18n.locale
            this.isOpen = false
            this.$setCookie(`tscLang`, this.$root.$i18n.locale, 1460)
            if (this.$route.translation && null !== this.$route.translation) {
                this.$router.push({ name: this.$route.translation.type, params: { lang: this.$route.translation.lang, id: this.$route.translation.post } })
            } else {
                let locale = this.$i18n.locale
                const touri = this.$router.resolve({ params: { lang: locale } })
                this.$router.push(touri.path)
            }
        }
    }
}
</script>
<style scoped>
.lSwitch {
    position: relative;
    display: flex;
    flex-direction: row;
    overflow: hidden;
    text-transform: uppercase;
    color: v-bind(aClr);
    /*border-radius: 100%;*/
}

.aLng,
.iLng {
    justify-content: center;
    align-items: center;
    /*border: 2px solid v-bind(aClr);
    border-radius: 100%;*/
    cursor: pointer;
    -ms-user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none;
    overflow: hidden;
    font-size: 1.1rem;
    /*width: 1.8rem;
    height: 1.8rem;
    margin: 2px 0 2px 0;*/
}

.aLng {
    display: flex;
    /*background-color: v-bind(aClr);
    color: white;*/
}

.lSwitch .iLng {
    display: none;
}

.lSwitch.tscOpenLng .iLng {
    display: flex;
    /*background-color: white;*/
    color: v-bind(aClr);
}

.lSwitch.tscOpenLng .iLng::before {
    content: "|";
    /*width: 1.8rem;
    height: 1.8rem;
    margin: 2px 0 2px 0;*/
    margin: 0 0.3rem;
    font-size: 1.1rem;
}
</style>