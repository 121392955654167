<template>
    <div class="divBlock" :class="{ 'divRow': isRow && cls }" :style="{ transform: transform }">
        <slot></slot>
    </div>
</template>
<script>

export default {
    name: `BlockComp`,
    props: {
        width: [Number, String],
        height: String,
        row: Boolean,
        transform: String
    },
    data() {
        return {
            //widthp: (Number.isInteger(this.width) || (Number.isFinite(this.width) && !Number.isInteger(this.width))) ? `${this.width}%` : this.width,
            //widthx: `${window.innerWidth * (this.width / 100)}px`,
            cls: `p-${this.width}`,
            direction: (typeof this.row !== `undefined` && this.row) ? `row` : `column`,
            isRow: (typeof this.row !== `undefined` && this.row) ? true : false,
            //part: parseInt(window.innerWidth / (window.innerWidth * (this.width / 100))),
        }
    },
    computed: {
        widthp() {
            if (!this.$isMobile().mobile) {
                return (Number.isInteger(this.width) || (Number.isFinite(this.width) && !Number.isInteger(this.width))) ? `${this.width}%` : this.width
            } else {
                return `100vw`
            }
        }
    }
}
</script>
<style scoped>
.divRow {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(600px, 1fr));
    /*this */
    gap: 10px;
    box-sizing: border-box;
}

.divBlock {
    position: relative;
    display: inline-flex;
    flex-direction: v-bind(direction);
    justify-content: center;
    align-items: center;
    width: v-bind(widthp);
    height: v-bind(height);
    /*background-color: red;*/
    margin: 0;
    /*flex: max(v-bind(widthx), 100%/v-bind(part) - 1rem);*/
    overflow: hidden;
    box-sizing: border-box;
}
</style>