<template>
    <div v-if="shn" class="noti-wrap">
        <div class="noti-head"></div>
        <div class="noti-body">{{ nfc }}</div>
        <div class="noti-foot"></div>
    </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
    name: `NotificationComponent`,
    data() {
        return {
            shn: false,
            nfc: null,
            clr: this.$colors.main
        }
    },
    computed: mapState(['showNotification', 'notificationBody']),
    watch: {
        showNotification(newVal) {
            this.shn = newVal
        },
        notificationBody(newVal) {
            if (newVal !== null) {
                this.nfc = newVal
                let lng = Math.ceil(newVal.length / 50) * 3000
                setTimeout(() => {
                    this.$store.commit(`resetNotification`)
                }, lng)
            }
        }
    }
}
</script>
<style scoped>
.noti-wrap {
    position: fixed;
    bottom: 1rem;
    left: 1rem;
    background-color: white;
    border: 1px solid black;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    box-shadow: 5px -5px 0px v-bind(clr);
    -webkit-box-shadow: 5px -5px 0px v-bind(clr);
    z-index:100;
}

@media screen and (max-width: 768px) {
    .noti-wrap {
        bottom: 2vh !important;
        left: 50% !important;
        transform: translateX(-50%);
        width: 90vw !important;
        max-height: 40vh;
        overflow-y: scroll;
        box-shadow: 5px -5px 0px v-bind(clr) !important;
        -webkit-box-shadow: 5px -5px 0px v-bind(clr) !important;
        box-sizing: border-box;
    }
}
</style>