<template>
    <div class="loader-wrap">
        <div class="loader-ring">
        </div>
        <IconComponent :icon="`tsclogo`" :strokeColor="`#7FA373`" :strokeWidth="0" :fillColor="`#7fa373`"
            :size="`140px`">
        </IconComponent>
    </div>
</template>
<script>
import IconComponent from './IconComponent.vue';

export default {
    name: `LoaderComponent`,
    components: {
        IconComponent
    }
}
</script>
<style scoped>
.loader-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    height: 100%;
}

.svg-wrap {
    width: 140px;
    height: 140px;
}

.loader-ring {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 280px;
    height: 280px;
    margin: 1px;
    border-radius: 50%;
    border: 3px solid #7fa373;
    border-color: #7fa373 transparent #7fa373 transparent;
    animation: ldr-ring 2s linear infinite;
}

/*
.loader-ring:after {
    content: " ";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 250px;
    height: 250px;
    margin: 1px;
    border-radius: 50%;
    border: 2px solid #7fa373;
    border-color: #7fa373 transparent #7fa373 transparent;
    animation-direction: reverse;
    animation: ldr-ring 1s linear infinite;
}*/

.loader-ring:before {
    content: " ";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 220px;
    height: 220px;
    margin: 1px;
    border-radius: 50%;
    border: 3px solid #7fa373;
    border-color: #7fa373 transparent #7fa373 transparent;
    animation: ldr-ring 1s linear infinite;
    animation-direction: reverse;
}

@keyframes ldr-ring {
    0% {
        transform: translate(-50%, -50%) rotate(0deg);
    }

    100% {
        transform: translate(-50%, -50%) rotate(360deg);
    }
}

@keyframes ldr-ring-back {
    0% {
        transform: translate(-50%, -50%) rotate(0deg);
    }

    100% {
        transform: translate(-50%, -50%) rotate(-360deg);
    }
}
</style>