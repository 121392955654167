import { createRouter, createWebHistory } from 'vue-router'
import { i18n } from '@/main'
//import { nextTick } from 'vue'

const routes = [{
  path: '/:lang',
  //component: { template: `<router-view></router-view>` },
  beforeEnter(to, from, next) {
    const lang = to.params.lang
    //console.log('NEW', lang, [`hu`, `en`].includes(lang))
    
    /*if (![`hu`, `en`].includes(lang)) {
      return next(`hu`)
    }
    if (i18n.locale !== lang) {
      i18n.locale = lang
    }*/
    /*function validateRoute(name) {
      console.log('CHECKING: ', name, [`about`, `home`, `events`, `event`, `offer`, `contact`, `notfound`].indexOf(name))
      return [`about`, `home`, `events`, `event`, `offer`, `contact`].indexOf(name) > -1 ? true : false
    }

    if (typeof to.name === `undefined` || !validateRoute(to.name)) {
      if (![`hu`, `en`].includes(lang)) {
        i18n.locale = `hu`
        //return next(`hu`)
      }
      return next({ name: `notfound`, params: { lang: i18n.locale } })
    }*/

    //console.log('NEW', lang, [`hu`, `en`].includes(lang))
    if (![`hu`, `en`].includes(lang)) {
      i18n.locale = `hu`
      return next(`hu`)
      //return next(`hu`)
    }
    if (i18n.locale !== lang) {
      i18n.locale = lang
    }
    return next()
  },
  children: [{
    path: '',
    name: 'home',
    component: () => import(`@/views/HomeView.vue`)
  },
  {
    path: 'about',
    name: 'about',
    component: () => import(`@/views/AboutView.vue`)
  },
  {
    path: 'offer',
    name: 'offer',
    component: () => import(`@/views/OfferView.vue`)
  },
  {
    path: `post/:id`,
    name: `post`,
    component: () => import(`@/views/PostView.vue`)
  },
  {
    path: `contact`,
    name: `contact`,
    component: () => import(`@/views/ContactView.vue`),
  },
  {
    path: `venue`,
    name: `venue`,
    component: () => import(`@/views/VenueView.vue`)
  },
  {
    path: `events`,
    name: `events`,
    component: () => import(`@/views/EventListView.vue`)
  },
  {
    path: `event/:id`,
    name: 'event',
    component: () => import(`@/views/EventView.vue`)
    },
    {
      path: `unsubscribe/:email?`,
      name: `unsubscribe`,
      component: () => import(`@/views/UnsubscribeView.vue`)
  },
  {
    path: `notfound`,
    name: `notfound`,
    component: () => import(`@/views/NotfoundView.vue`)
    },
    {
      path: `*`,
      redirect:`notfound`
  }
  ]
}]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (typeof to.params.section !== `undefined`) {
      return {
        el: `#${to.params.section}`,
        top: 100,
        behavior: `smooth`
      }
    }
    if (savedPosition) {
      return savedPosition
    }
    return { x: 0, y: 0 }
  },
})

router.beforeEach((to, from, next) => {
  const lang = to.params.lang

  /*function validateRoute(name) {
    console.log('CHECKING: ', name, [`about`, `home`, `events`, `event`, `offer`, `contact`,`notfound`].indexOf(name))
    return [`about`, `home`, `events`, `event`, `offer`, `contact`].indexOf(name) > -1 ? true : false
  }

  if (typeof to.name === `undefined` || !validateRoute(to.name)) {
    if (![`hu`, `en`].includes(lang)) {
      i18n.locale = `hu`
      //return next(`hu`)
    }
    return next({ name: `notfound`, params: { lang: i18n.locale } })
  }*/

  //console.log('NEW', lang, [`hu`, `en`].includes(lang))
  if (![`hu`, `en`].includes(lang)) {
    i18n.locale=`hu`
    return next(`hu`)
    //return next(`hu`)
  }
  if (i18n.locale !== lang) {
    i18n.locale = lang
  }
  //i18n.locale = localStorage.getItem('language') || 'hu'
  return next()
})
/*
router.afterEach((to) => {
  console.log('AFTER', 'TO: ', to)
})*/

export default router
