<template>
    <!--div id="logoWrapper" @click="homeNavg">
        <IconComponent :icon="`tsclogo`" :size="`4.5rem`" :strokeWidth="0.7" :fillColor="clr"
            :strokeColor="`transparent`"></IconComponent>
        <h3 v-if="!ismob">HÁROM SZERB KÁVÉHÁZ</h3>
    </div-->
    <div v-if="!ismob" id="logoWrapper" @click="homeNavg">
            <IconComponent :icon="`tsclogo-xl`" :size="`8vh`" :dimension="`h`" :strokeWidth="0.7" :fillColor="clr"
                :strokeColor="`transparent`"></IconComponent>
    </div>
    <div v-else id="logoWrapper" @click="homeNavg">
            <IconComponent :icon="`tsclogo-mid`" :size="`10vh`" :strokeWidth="0.7" :fillColor="clr"
                :strokeColor="`transparent`"></IconComponent>
    </div>
</template>
<script>
import IconComponent from '@/components/IconComponent.vue'

export default {
    name: 'LogoComp',
    props: {
        src: String,
        ismob: Boolean,
        wh: String
    },
    data() {
        return {
            clr: this.$colors.main
        }
    },
    methods: {
        homeNavg() {
            this.$router.push({ name: `home`, params: { lang: this.$i18n.locale } })
        }
    },
    components: {
        IconComponent
    }
}
</script>
<style scoped>
#logoWrapper,
#logoWrapper h3 {
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: pointer;
}

h3 {
    color: v-bind(clr);
    letter-spacing: 2px;
    font-weight: 500;
}
</style>