<template>
    <div style="z-index:10002">
        <div class="search-icon" @click="openSearch">
            <IconComponent :icon="`magnifier`" :strokeWidth="2.2" :strokeColor="this.$isMobile().mobile ? `white` : this.$colors.main"></IconComponent>
        </div>
        <Transition name="searchtr">
            <div v-if="showSearch" class="search-wrap">
                <div class="sec-menu">
                    <div class="navLogo">
                        <LogoComp :src="img" :ismob="isMob" :wh="`2rem`"></LogoComp>
                    </div>
                    <LangSwitch></LangSwitch>
                </div>
                <div class="search-row" :style="{ backgroundColor: this.$colors.main }">
                    <div class="spacer-block"></div>
                    <div class="search-block">
                        <input v-model="searchValue" type="text" :placeholder="this.$t(`placeholders.search`)"
                            @keyup="initSearch" />
                        <div class="plch-icon">
                            <IconComponent :icon="`magnifier`" :strokeWidth="3" :size="`1rem`"></IconComponent>
                        </div>
                    </div>
                    <div class="close-block">
                        <div @click="closeSearch" class="close-search">
                            <IconComponent :icon="`close`" :strokeWidth="2"></IconComponent>
                        </div>
                    </div>
                </div>
                <div class="result-row">
                    <div v-if="!isLoading && searchResult === null">
                        <h1 class="def-txt">{{ $t(`searchinit`) }}</h1>
                    </div>
                    <div v-else-if="isLoading" class="loaderWrap">
                        <!--div class="loading">
                            <div class="lds-ring">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                        </div-->
                        <LoaderComponent></LoaderComponent>
                    </div>
                    <div v-else-if="!isLoading && searchResult !== null && !isEmpty" class="cards-wrap">
                        <div v-for="(item, index) in searchResult" :key="index" class="result-card">
                            <h3 v-if="item.title" class="rcard-title">{{ item.title }}</h3>
                            <p v-if="item.text" class="rcard-text">{{ item.text }}</p>
                            <div v-if="item.link">
                                <div @click="cardOpen(item.redirect)" class="rcard-open">{{ $t(`forward`) }}</div>
                            </div>
                        </div>
                    </div>
                    <div v-if="!isLoading && isEmpty">
                        <h1 class="def-txt">{{ $t(`nosearchresult`) }}</h1>
                    </div>
                </div>
            </div>
        </Transition>
    </div>
</template>
<script>
import LangSwitch from './LangSwitch.vue';
import LogoComp from './LogoComp.vue';
import IconComponent from './IconComponent.vue';
import LoaderComponent from './LoaderComponent.vue';

export default {
    name: `SearchComponent`,
    props: {
        img: String,
        isMob: Boolean
    },
    data() {
        return {
            clr: this.$colors.main,
            showSearch: false,
            searchValue: null,
            lastEvent: null,
            searchResult: null,
            isLoading: false,
            isEmpty: null
        };
    },
    methods: {
        cardOpen(link) {
            this.closeSearch()
            this.$router.push(link)
        },
        startSearch() {
            fetch(`${this.$apiUrl}/wp-json/tsc-api/v1/search/?qstr=${this.searchValue}&lng=${this.$i18n.locale}`)
                .then(res => res.json())
                .then(data => {
                    if (data.length === 0) {
                        this.isEmpty = true
                    }
                    if (!this.$isMobile().mobile) {
                        let m = data.length % 3
                        for (let i = 0; i < (3 - m); i++) {
                            data.push([])
                        }
                    }
                    this.searchResult = data
                    this.isLoading = false
                })
        },
        initSearch() {
            if (this.searchValue.length >= 2) {
                let timeStamp = new Date().getTime()
                this.lastEvent = timeStamp
                setTimeout(() => {
                    this.isLoading = true
                    if (timeStamp === this.lastEvent) {
                        this.startSearch()
                    }
                }, 700)

            }
        },
        openSearch() {
            this.showSearch = true;
            document.querySelector(`body`).classList.add(`noScroll`)
        },
        closeSearch() {
            this.showSearch = false;
            document.querySelector(`body`).classList.remove(`noScroll`)
        },
        validateinput() { },
        sendSearch() { }
    },
    components: { LangSwitch, LogoComp, IconComponent, LoaderComponent }
}
</script>
<style scoped>
.search-icon,
.close-search {
    width: 1.7rem;
    display: flex;
    justify-content: center;
    align-items: center;
    -moz-user-select: none;
    -ms-user-select: none;
    -webkit-user-select: none;
    user-select: none;
    cursor: pointer;
}

.sec-menu {
    display: flex;
    height: 9vh;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 3vw 0.5rem 1.5vw;
    box-sizing: border-box;
}

.sec-menu div {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 1rem;
}

.lSwitch {
    padding: 0 2rem !important;
}

.search-row {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 9vh;
    box-sizing: border-box;
}

.search-block {
    width: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.plch-icon {
    position: absolute;
    top: 50%;
    left: 0.3rem;
    transform: translateY(-50%);
    width: 1rem;
    -moz-user-select: none;
    -ms-user-select: none;
    -webkit-user-select: none;
    user-select: none;
    cursor: none;
}

.search-block input {
    width: 100%;
    line-height: 2.2rem;
    font-size: 1.2rem;
    border-radius: 0;
    background-color: transparent;
    color: white;
    outline: none;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid white !important;
    padding-left: 2.2rem;
}

.search-block input::placeholder {
    color: rgba(255, 255, 255, 0.8)
}

.spacer-block,
.close-block {
    width: 15%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.result-row {
    height: 82vh;
    width: 100%;
    box-sizing: border-box;
    padding: 1vw;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    overflow-y:auto;
}

.def-txt {
    color: v-bind(clr)
}

.search-wrap {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: white;
    z-index: 9993342543932;
}

.searchtr-enter-active,
.searchtr-leave-active {
    transition: all 0.2s ease-in-out;
}

.searchtr-enter-from,
.searchtr-leave-to {
    height: 0;
    opacity: 0;
}

.loaderWrap {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1501;
}

.loading {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: v-bind(clr);
    font-weight: 600;
    font-size: 1.2rem;
}

.lds-ring {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    height: 100%;
}

.lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 5rem;
    height: 5rem;
    margin: 1rem;
    border: 4px solid #fff;
    border-radius: 100%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: v-bind(clr) transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
}

@keyframes lds-ring {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.cards-wrap {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: start;
    flex-wrap: wrap;
    padding: 1% 5vw;
    box-sizing: border-box;
    overflow-y: auto;
    overflow-x: hidden;
}

.result-card {
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 2%;
    box-sizing: border-box;
    height: 30%;
}

.result-card h3 {
    height: 7%;
    margin: 0.3rem 0 0.5rem 0;
    text-transform: uppercase;
    color: v-bind(clr);
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
}

.result-card p {
    text-overflow: ellipsis;
    word-wrap: break-word;
    overflow: hidden;
    height: calc(1.5rem*3);
    line-height: 1.5rem !important;
    margin: 0.5rem 0;
    box-sizing: border-box;
    font-weight: 500;
}

.result-card div {
    height: 7%;
    width: 100%;
    margin: 0.5rem 0 0.3rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1%;
    box-sizing: border-box;
}

.rcard-open {
    padding: 0.2rem 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid v-bind(clr);
    color: v-bind(clr);
    background-color: white;
    box-sizing: border-box;
    transition: all 0.2 ease-in-out;
    -ms-user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none;
    cursor: pointer;
}

.rcard-open:hover {
    color: white;
    background-color: v-bind(clr);
}

@media screen and (max-width: 1000px) {
    .cards-wrap {
        flex-direction: column;
        align-items: center;
        justify-content: start;
        flex-wrap: nowrap;
        height: 100%;
    }

    .result-card {
        height: 40%;
        width: 100%;
    }

    .result-card h3 {
        height: unset;
    }

    .result-card p {
        max-height: calc(1.5rem*3);
        height: unset;
    }

    .result-card div {
        height: unset;
    }

    .def-txt {
        font-size: 1.3rem;
        text-align: center;
        padding: 0 5%;
    }
}
</style>