<template>
  <div v-show="!accepted" class="consent-wrap">
    <div class="consent-title">{{ $t(`cookie.title`) }}</div>
    <div class="consent-body" v-dompurify-html="$t(`cookie.text`)"></div>
    <div class="consent-footer">
      <ButtonComponent
        :txt="`OK`"
        :btype="`main`"
        @click="consentCookies"
      ></ButtonComponent>
    </div>
  </div>
</template>
<script>
import { defineComponent } from "vue";
import ButtonComponent from "@/components/ButtonComponent.vue";

export default defineComponent({
  name: `CookiePolicy`,
  components: { ButtonComponent },
  data() {
    return {
      accepted: false,
    };
  },
  mounted() {
    this.accepted = this.$store.getters.getCookieConsent;
  },
  methods: {
    consentCookies() {
      this.accepted = true;
      this.$store.commit(`setCookieConsent`, { cookieConsent: this.accepted });
    },
  },
});
</script>
<style scoped>
.consent-wrap {
  position: fixed;
  bottom: 1.5rem;
  left: 50%;
  transform: translateX(-50%);
  width: 90vw;
  max-height: 50vh;
  border: 2px solid #7fa737;
  box-shadow: 5px 5px 0px #e3e3e3 !important;
  -webkit-box-shadow: 5px 5px 0px #e3e3e3 !important;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  background-color: #fff9f4;
  z-index: 98279872393279;
}
.consent-title {
  font-size: 1.3rem;
  font-weight: 500;
  color: #7fa737;
  text-transform: uppercase;
  text-align: start;
}
.consent-body {
  margin-top: 0.5rem;
  font-size: 1.05rem;
  text-align: start;
}

.consent-body::v-deep(a) {
  color: #7fa737;
}
.consent-footer {
  display: flex;
  justify-content: flex-end;
  margin-top: 0.5rem;
}

@media screen and (max-width: 700px) {
  .consent-wrap {
    max-height: 80vh;
    padding: 1rem;
  }

  .consent-body {
    font-size: 0.9rem;
    text-align: justify;
  }

  .btn-wrap {
    margin: 0;
  }
}
</style>
