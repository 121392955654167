<template>
  <nav-menu></nav-menu>
  <router-view :key="$route.path" v-if="!maintenance"></router-view>
  <div v-else>
    <h1>{{$t(`labels.maintenance.title`)}}</h1>
    <p>{{$t(`labels.maintenance.detailshu`)}}</p>
    <p>{{$t(`labels.maintenance.detailsen`)}}</p>
  </div>
  <FooterComp></FooterComp>
  <NotificationComponent></NotificationComponent>
  <CookiePolicy></CookiePolicy>
</template>
<script>
import NavMenu from '@/components/NavMenu.vue'
import NotificationComponent from '@/components/NotificationComponent.vue'
import CookiePolicy from '@/components/CookiePolicy'
import FooterComp from './components/FooterComp.vue'

export default {
  name: "App",
  components: { NavMenu, NotificationComponent, FooterComp, CookiePolicy },
  data() {
    return { clr: this.$colors.main, maintenance: this.$maintenance }
  },
  mounted() {
    let locs = this.$i18n.availableLocales
    let q = this.$route.query
    if (q.lang && locs.indexOf(q.lang) > -1) {
      this.$i18n.locale = q.lang
      this.$setCookie(`tscLang`, q.lang, 1460)
    }
  }
}
</script>
<style>
body::-webkit-scrollbar {
  display: none;
}

body {
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-x: hidden;
  background-color: white;
  /*height: 100vh;*/
  margin: calc(9vh - 2vw) 0 0 0;
  background-color: #fff9f4;
}

body.noScroll {
  height: 100vh !important;
  overflow-y: hidden !important;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: 'Poppins', sans-serif !important;
  color: v-bind(clr);
}

p,
a,
div,
span {
  font-family: 'Poppins', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
p,
a,
i {
  text-align: start;
}

#app {
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin: 0 0 0 0;
  background-color: #fff9f4;
  width: 100%;
  overflow-x: hidden;
  padding: 2vw;
  box-sizing: border-box;
  font-family: 'Roboto Condensed', sans-serif;
  font-family: 'Work Sans', sans-serif;
}

@media screen and (max-width: 1000px) {
  body {
    margin: 9vh 0 0 0;
    height: auto;
  }
}
</style>
<style scoped>
div :deep(.has-small-font-size) {
  font-size: 13px;
  margin: 0;
}

div :deep(.has-medium-font-size) {
  font-size: 1.25rem;
  margin: 0.2rem 0;
}

div :deep(.has-large-font-size) {
  font-size: 1.8rem;
  margin: 0.4rem 0;
}

div :deep(.has-x-large-font-size) {
  font-size: 40px;
  margin: 0.5rem 0;
}

div :deep(.nomargin) {
  margin: 0;
}
</style>