import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import { createStore } from "vuex";
import { createI18n } from "vue-i18n";
import { languages } from "@/translations/index";
import { defaultLocale } from "@/translations/index";
import VueDOMPurifyHTML from "vue-dompurify-html";
import LoaderComponent from "./components/LoaderComponent.vue";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
//import { confData } from './modules/getOptions'
//import { tscConf } from '../public/cfg/tsc-conf'

const firebaseConfig = {
  apiKey: "AIzaSyAznj7cZM1Lkxw_ZmWH_88RPoKpJDoBBlw",
  authDomain: "tsc-test-e7abf.firebaseapp.com",
  projectId: "tsc-test-e7abf",
  storageBucket: "tsc-test-e7abf.appspot.com",
  messagingSenderId: "113288748044",
  appId: "1:113288748044:web:6581a4d79e18e318352229",
  measurementId: "G-YKY4SLY2FF",
};

const firebaseapp = initializeApp(firebaseConfig);
getAnalytics(firebaseapp);

const messages = Object.assign(languages);

const i18n = createI18n({
  locale: defaultLocale,
  fallbackLocale: "hu",
  messages,
});

const store = createStore({
  state() {
    return {
      showNotification: false,
      notificationBody: null,
      eventsReg: [],
      newsletterSubscribed: false,
      cookieConsent: false,
    };
  },
  getters: {
    showNoti: function (state) {
      return state.showNotification;
    },
    notiBody: function (state) {
      return state.notificationBody;
    },
    getRegs: function (state) {
      return state.eventsReg;
    },
    getSubscribed: function (state) {
      return state.newsletterSubscribed;
    },
    getCookieConsent: function (state) {
      return state.cookieConsent;
    },
  },
  mutations: {
    initialiseStore(state) {
      // Check if the ID exists
      if (window.localStorage.getItem("tsc-store")) {
        // Replace the state object with the stored item
        this.replaceState(
          Object.assign(
            state,
            JSON.parse(window.localStorage.getItem("tsc-store"))
          )
        );
      }
    },
    setNotification(state, payload) {
      state.notificationBody = payload.c;
      state.showNotification = true;
    },
    resetNotification(state) {
      state.notificationBody = null;
      state.showNotification = false;
    },
    setRegistered(state, payload) {
      state.eventsReg.push({
        event: payload.event,
        registered: true,
        user: payload.email,
      });
    },
    setSubscribed(state) {
      state.newsletterSubscribed = !state.newsletterSubscribed;
    },
    setCookieConsent(state, payload) {
      state.cookieConsent = payload.cookieConsent;
    },
  },
});

store.subscribe((mutation, state) => {
  window.localStorage.setItem(`tsc-store`, JSON.stringify(state));
});

const app = createApp({
  extends: App,
  beforeCreate() {
    this.$store.commit("initialiseStore");
  },
});
app.use(store).use(router).use(i18n).use(VueDOMPurifyHTML);
app.component(`LoaderComponent`, LoaderComponent);
app.config.globalProperties.$isMobile = () => {
  let sw = screen.width;
  let sh = screen.height;
  if (sw > 768) {
    return {
      mobile: false,
      width: sw,
      height: sh,
      orientation: sh > sw ? `portrait` : `landscape`,
    };
  } else {
    return {
      mobile: true,
      width: sw,
      height: sh,
      orientation: sh > sw ? `portrait` : `landscape`,
    };
  }
};
let uri = `https://haromszerbadmin.josz.org`;
app.config.globalProperties.$serverDate = (date) => {
  let timeZone = `Europe/Budapest`;
  if (typeof date === "string") {
    return new Date(
      new Date(date).toLocaleString("en-US", {
        timeZone: timeZone,
      })
    );
  }
  return new Date(
    date.toLocaleString("en-US", {
      timeZone: timeZone,
    })
  );
};
//app.config.globalProperties.$orsToken = `5b3ce3597851110001cf6248ac1b74fdb0e34767bd0abb113da3e9dc`
app.config.globalProperties.$setCookie = (cname, cvalue, exdays) => {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  let expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
};
app.config.globalProperties.$getCookie = (cname) => {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return false;
};
app.config.globalProperties.$getOS = () => {
  let userAgent = window.navigator.userAgent;
  let platform = window.navigator.platform;
  let macosPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K"];
  let windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"];
  let iosPlatforms = ["iPhone", "iPad", "iPod"];
  let os = null;

  if (macosPlatforms.indexOf(platform) !== -1) {
    os = "Mac OS";
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    os = "iOS";
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = "Windows";
  } else if (/Android/.test(userAgent)) {
    os = "Android";
  } else if (!os && /Linux/.test(platform)) {
    os = "Linux";
  }

  return os;
};
app.config.globalProperties.$app = app;
app.config.globalProperties.$apiUrl = uri;
fetch(`${uri}/wp-json/tsc-api/v1/contact`)
  .then((r) => r.json())
  .then((data) => {
    app.config.globalProperties.$tscoptions = data;
    app.config.globalProperties.$maintenance = false;
    app.config.globalProperties.$openimg = data.openimg;
    app.config.globalProperties.$closeimg = data.closeimg;
    app.config.globalProperties.$menuimgs = data.menuimgs;
    app.config.globalProperties.$orsToken = data.orskey;
    app.config.globalProperties.$colors = {
      main: data.mainclr,
      secondary: data.secondaryclr,
      ascend: data.ascendclr,
      menu: data.menuclr,
      random: [data.rndclr1, data.rndclr2, data.rndclr3],
      all: [
        data.mainclr,
        data.secondaryclr,
        data.ascendclr,
        data.menuclr,
        data.rndclr1,
        data.rndclr2,
        data.rndclr3,
      ],
    };
    app.config.globalProperties.$isOpenAt = (dt, facility) => {
      const nowDate = new Date();
      const selectorDate = new Date(
        nowDate.toLocaleString("en-US", {
          timeZone: `Europe/Budapest`,
        })
      );
      let idx = {
        1: `mon`,
        2: `tue`,
        3: `wed`,
        4: `thu`,
        5: `fri`,
        6: `sat`,
        7: `sun`,
      };
      let dy = selectorDate.getDay() === 0 ? 7 : selectorDate.getDay();
      let day = data.openhours[facility][idx[dy]];
      if (day !== `closed`) {
        let st = day.start;
        let en = day.end;
        let now =
          (dt.getHours() < 10 ? `0` + dt.getHours() : dt.getHours()) +
          `:` +
          (dt.getMinutes() < 10 ? `0` + dt.getMinutes() : dt.getMinutes());
        return now >= st && now <= en;
      } else {
        return false;
      }
    };
    app.mount("#app");
  });
export { i18n };
