export class mailAddress {
    constructor(mail, lang) {
        this.lang = lang
        this.mail = mail
        this.arr = this.mail.split(`@`)
        this.addr = this.arr[0]
        this.domain = this.arr[1]
        this.corrected = this.arr.join(`</span><span class="tscMailer" style="opacity:0;width:0;position:absolute;">[</span><span>@</span><span class="tscMailer" style="opacity:0;width:0;position:absolute;">]</span><span>`)
    }

    getMail() {
        return `<div class="tscContactObj"><span>${this.corrected}</span></div>`
    }

    onClick() {
        let txt = (this.lang === `hu`) ? `Kérdés, érdeklődés honlapon keresztül` : `Question, ask for info via website`
        let subj = encodeURIComponent(txt)
        let a = document.createElement('a')
        a.href = `mailto:${this.mail}?subject=${subj}`
        document.body.appendChild(a)
        a.click()
        a.remove()
    }
}