<style scoped>
.footer-wrap {
    border-top: 1px solid v-bind(clr);
    width: 100vw;
    transform: translate(-2vw, 2vw);
    padding: 1vh 2vw 4vh 2vw;
    box-sizing: border-box;
    background-color: white;
    margin-top: 0rem;
}

.footer-block {
    align-items: flex-start;
    justify-content: flex-start;
}

.footer-block h4 {
    font-family: 'Poppins', sans-serif;
    text-transform: uppercase;
    color: v-bind(clr);
    font-weight: 400;
    text-align: start;
    padding: 0;
    margin: 0 0 1.5rem 0;
    line-height:2rem;
    vertical-align: baseline;
}

p {
    font-size: 0.8rem;
    color: v-bind(clr);
    margin: 0.2rem 0 !important;
}

p span {
    display: inline-flex;
}
</style>
<template>
    <div class="footer-wrap" v-if="!this.$isMobile().mobile && show" :style="{display: show ? `block` : `none`}">
        <BlockComp :row="false" :width="25" class="footer-block">
            <h4>{{$t(`contact`)}}</h4>
            <p @click="navMap" class="tscInfoRow">{{ contact.postal }} {{ contact.city }}, {{ contact.address}}</p>
            <p class="tscInfoRow">
                <span class="tscCntSpan" v-dompurify-html="phoneNr" @click="phoneObj.onClick"></span>
            </p>
            <p class="tscInfoRow">
                <span class="tscCntSpan" v-dompurify-html="mailAddr" @click="mailObj.onClick"></span>
            </p>
        </BlockComp>
        <BlockComp :row="false" :width="25" class="footer-block">
            <h4>{{$t(`info`)}}</h4>
            <p @click="navOpenh" style="cursor:pointer">{{$t(`openhours`)}}</p>
            <p @click="navBook" style="cursor:pointer">{{$t(`book`)}}</p>
            <!--p @click="navNewsletr" style="cursor:pointer">{{$t(`newsletter`)}}</p-->
        </BlockComp>
        <BlockComp :row="false" :width="25"></BlockComp>
        <BlockComp :row="true" :width="25" class="footer-block" style="justify-content:flex-end;transform:translateY(0.6rem)">
            <a :href="this.$tscoptions.fb" target="_blank">
                <IconComponent :icon="`facebook`" :size="`1.5rem`" :strokeColor="clr" :strokeWidth="1.5"></IconComponent>
            </a>
            <a :href="this.$tscoptions.insta" target="_blank">
                <IconComponent :icon="`instagram`" :size="`1.5rem`" :strokeColor="clr" :strokeWidth="0.5"></IconComponent>
            </a>
        </BlockComp>
    </div>
    <div v-else-if="this.$isMobile().mobile && show" class="footer-wrap-mobile">
        <BlockComp :row="true" :width="100" class="footer-block-mobile">
            <IconComponent :icon="`mapmarker`" :size="`1.3rem`" :strokeColor="`white`" :strokeWidth="0.1" :fillColor="`white`" @click="navMap"></IconComponent>
            <a href="#" @click="phoneObj.onClick"><IconComponent :icon="`phone`" :size="`1.3rem`" :strokeColor="`white`" :strokeWidth="0.1" :fillColor="`white`"></IconComponent></a>
            <a href="#" @click="mailObj.onClick"><IconComponent :icon="`email`" :size="`1.3rem`" :strokeColor="`white`" :strokeWidth="0.1" :fillColor="`white`"></IconComponent></a>
        <a :href="this.$tscoptions.fb" target="_blank">
            <IconComponent :icon="`facebook`" :size="`1.3rem`" :strokeColor="`white`" :fillColor="`white`" :strokeWidth="0.1"></IconComponent>
        </a>
        <a :href="this.$tscoptions.insta" target="_blank">
            <IconComponent :icon="`instagram`" :size="`1.3rem`" :strokeColor="`white`" :strokeWidth="0.8"></IconComponent>
        </a>
        </BlockComp>
    </div>
</template>
<script>
import BlockComp from "./BlockComp.vue";
import IconComponent from "./IconComponent.vue";
import { mailAddress } from '@/modules/mailAddress'
import { phoneNr } from '@/modules/phoneNr'

export default {
    name: `FooterComp`,
    props: {
        targ: {
            type: String,
            default:`desktop`
        }
    },
    data() {
        return {
            clr: this.$colors.main,
            contact: this.$tscoptions,
            phoneNr: null,
            mailAddr: null,
            mailObj: new mailAddress(this.$tscoptions.email, this.$i18n.locale),
            phoneObj: new phoneNr(this.$tscoptions.phone),
        }
    },
    computed: {
        show() {
            if (this.targ === `desktop`) {
                if (this.$isMobile().mobile) {
                    return false
                } else {
                    return true
                }
            } else {
                if (this.$isMobile().mobile) {
                    return true
                } else {
                    return false
                }
            }
        }
    },
    mounted() {
        this.mailAddr = this.mailObj.getMail()
        this.phoneNr = this.phoneObj.getNr()
    },
    methods: {
        navBook() {
            this.$router.push({ name: `contact`, params: { lang: this.$i18n.locale, section: `tbooking` } })
        },
        navOpenh() {
            this.$router.push({ name: `contact`, params: { lang: this.$i18n.locale, section: `openhours` } })
        },
        navNewsletr() {
            this.$router.push({ name: `contact`, params: { lang: this.$i18n.locale, section: `newsletter` } })
        },
        navMap() {
            if (this.$isMobile().mobile) {
                this.$emit(`navMap`)
            }
            this.$router.push({ name: `contact`, params: { lang: this.$i18n.locale, section: `contactmap` } })
        }
    },
    components: { BlockComp, IconComponent }
}
</script>