<template>
  <nav
    :class="{
      mobileNav: isMob,
      openedNav: openedB,
      navInit: !isScroll,
      navScroll: isScroll,
    }"
  >
    <div id="navLogo" :class="{ openedLogo: openedB && isMob }">
      <LogoComp :src="img" :ismob="isMob" :wh="`2rem`"></LogoComp>
    </div>
    <div :class="{ openedNavigation: openedB && isMob }" id="menuLinks">
      <!--router-link @click="burgerSwitch" :to="{ name: `home`, params: { lang: this.$i18n.locale } }">
                <span>{{ $t(`home`) }}</span>
                <span class="activeMark"></span>
            </router-link-->
      <!--div v-if="isMob" :style="{height: this.$isMobile().mobile ? `20vw` : `unset`, margin:this.$isMobile().mobile ? `20.8px` : `0` }">
                <IconComponent :icon="`tsclogo`" :size="`20vw`" :fillColor="`white`"></IconComponent>
            </div-->
      <div
        v-if="isMob"
        style="
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          opacity: 0.7;
          filter: blur(1px);
        "
      >
        <IconComponent
          :icon="`tsclogo`"
          :size="`80vh`"
          :fillColor="`transparent`"
          :strokeWidth="0.1"
        >
        </IconComponent>
      </div>
      <router-link
        @click="burgerSwitch"
        :to="{ name: `about`, params: { lang: this.$i18n.locale } }"
      >
        <span>{{ $t(`about`) }}</span>
        <span class="activeMark"></span>
      </router-link>
      <router-link
        @click="burgerSwitch"
        :to="{ name: `offer`, params: { lang: this.$i18n.locale } }"
      >
        <span>{{ $t(`offer`) }}</span>
        <span class="activeMark"></span>
      </router-link>
      <router-link
        @click="burgerSwitch"
        :to="{ name: `venue`, params: { lang: this.$i18n.locale } }"
      >
        <span>{{ $t(`venue`) }}</span>
        <span class="activeMark"></span
      ></router-link>
      <router-link
        @click="burgerSwitch"
        :to="{ name: `events`, params: { lang: this.$i18n.locale } }"
      >
        <span>{{ $tc(`events`, 100) }}</span>
        <span class="activeMark"></span>
      </router-link>
      <router-link
        @click="burgerSwitch"
        :to="{ name: `contact`, params: { lang: this.$i18n.locale } }"
      >
        <span>{{ $t(`contact`) }}</span>
        <span class="activeMark"></span>
      </router-link>
      <SearchComponent
        :img="img"
        :isMob="isMob"
        :style="{
          height: this.$isMobile().mobile ? `1.5rem` : `unset`,
          margin: this.$isMobile().mobile ? `20.8px` : `0 1rem`,
        }"
      >
      </SearchComponent>
      <LangSwitch
        :style="{
          height: this.$isMobile().mobile ? `1.5rem` : `unset`,
          margin: this.$isMobile().mobile ? `20.8px` : `0`,
        }"
      >
      </LangSwitch>
      <FooterComp
        :targ="`mobile`"
        @navMap="burgerSwitch"
        :style="{
          height: this.$isMobile().mobile ? `1.5rem` : `unset`,
          margin: this.$isMobile().mobile ? `20.8px` : `0`,
        }"
      >
      </FooterComp>
    </div>
    <div
      v-if="isMob"
      id="stepBack"
      :class="{ showBack: openedB }"
      @click="goBack"
    >
      <div></div>
      <div></div>
      <div></div>
    </div>
    <div
      v-if="isMob"
      id="hamburger"
      :class="{ openedBurger: openedB }"
      @click="burgerSwitch"
    >
      <div></div>
      <div></div>
      <div></div>
    </div>
  </nav>
</template>
<script>
import LogoComp from "./LogoComp.vue";
import LangSwitch from "./LangSwitch.vue";
import SearchComponent from "./SearchComponent.vue";
import FooterComp from "./FooterComp.vue";
import IconComponent from "./IconComponent.vue";

export default {
  name: "NavMenu",
  data() {
    return {
      isMob: this.$isMobile().mobile,
      openedB: false,
      screenWidth: parseFloat(screen.width * 0.3 * 2 - 16) + "px",
      offsideVal: parseFloat(0 - screen.width) + "px",
      img: `${this.$apiUrl}/wp-content/plugins/three-serbs-cafe/src/logos/drw-2.svg`,
      mainClr: `rgb(17, 17, 31)`,
      navInit: `navInit`,
      navScroll: `navScroll`,
      isScroll: false,
      clr: this.$colors.main,
      closedBcg: null,
      openedBcg: null,
      closedClr: null,
      openedClr: null,
      bcg: null,
      ccc: null,
    };
  },
  methods: {
    burgerSwitch() {
      if (this.isMob) {
        this.openedB = !this.openedB;
        if (this.openedB) {
          document.body.classList.add("noScroll");
        } else {
          document.body.classList.remove("noScroll");
        }
      }
    },
    goBack() {
      this.openedB = !this.openedB;
      this.$router.back();
    },
    handleScroll() {
      if (window.scrollY > 0.1 * window.innerHeight) {
        this.isScroll = true;
      } else {
        this.isScroll = false;
      }
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll, { passive: true });
    this.closedBcg = `white`;
    this.openedBcg = this.clr.menu;
    this.closedClr = this.clr.menu;
    this.openedClr = `white`;
    this.bcg = this.openedB ? this.openedBcg : this.closedBcg;
    this.ccc = this.openedB ? this.openedClr : this.closedClr;
  },
  components: {
    LogoComp,
    LangSwitch,
    SearchComponent,
    FooterComp,
    IconComponent,
  },
};
</script>
<style scoped>
@media screen and (min-width: 1001px) {
  nav {
    width: 100%;
    height: 9vh;
    box-sizing: border-box;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999999;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 2vw 0.5rem 2vw;
    background-color: white;
    transition: all 0.3s ease-in-out;
  }

  nav.navScroll {
    box-shadow: 0px -2px 15px #e3e3e3;
  }

  nav div {
    display: flex;
    justify-content: center;
    align-items: center;
    /*padding: 0 1rem;*/
  }

  nav div > a {
    /*font-family: 'Playfair Display', serif;*/
    padding: 0.3rem 1rem;
    /*font-weight: bold;*/
    text-transform: uppercase;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: v-bind(clr);
    text-decoration: none;
    font-size: 1.1rem;
    margin: 0 1rem;
    border: 1px solid white;
  }

  nav > div a > span.activeMark {
    width: 35%;
    height: 2px;
    position: absolute;
    bottom: 50%;
    left: 50%;
    transform: translate(-50%, 1rem);
    transition: all 0.2s ease-in-out;
  }

  nav
    div
    > a:not(.router-link-active.router-link-exact-active):hover
    span.activeMark {
    background-color: v-bind(clr);
    opacity: 0.5;
    /*border: 1px inset v-bind(clr.menu);
        border-radius: 1rem;*/
    /*text-decoration: underline;*/
  }

  nav > div a.router-link-active.router-link-exact-active > span.activeMark {
    background-color: v-bind(clr);
  }

  /*nav div>a.router-link-active.router-link-exact-active {
        font-weight: 600;
        color: white;
        border-radius: 1rem;
        background-color: v-bind(clr.menu);
        color: white;
    }*/

  nav div > a:last-child {
    /*margin: 0 0 0 1rem;*/
  }

  nav div > a.router-link-exact-active {
    /*color: #42b983;*/
  }
}

@media screen and (max-width: 1000px) {
  nav {
    width: 100%;
    height: 10vh;
    box-sizing: border-box;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999999;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 2vw 0.5rem 2vw;
    transition: all 0.3s ease-in-out;
    background-color: v-bind(bcg);
  }

  nav.openedNav {
    background-color: v-bind(clr);
  }

  nav div > a {
    font-family: "Playfair Display", serif;
    padding: 0.3rem 1rem;
    color: white;
    text-decoration: none;
    font-size: 1.1rem;
    margin: 1rem 0;
    display: flex;
    border: none;
    text-align: center;
    text-transform: uppercase;
    font-weight: 400;
    position: relative;
  }

  nav > div a > span.activeMark {
    width: 35%;
    height: 2px;
    position: absolute;
    bottom: 50%;
    left: 50%;
    transform: translate(-50%, 1rem);
    transition: all 0.2s ease-in-out;
  }

  nav
    div
    > a:not(.router-link-active.router-link-exact-active):hover
    span.activeMark {
    background-color: white;
    opacity: 0.5;
    /*border: 1px inset v-bind(clr.menu);
        border-radius: 1rem;*/
    /*text-decoration: underline;*/
  }

  nav > div a.router-link-active.router-link-exact-active > span.activeMark {
    background-color: white;
  }

  #menuLinks {
    width: 100%;
    height: 0 !important;
    position: absolute;
    top: 10vh;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 9999999;
    overflow: hidden;
    transition: all 0.3s ease-in-out;
    background-color: v-bind(bcg);
  }

  #menuLinks.openedNavigation {
    height: 90vh !important;
    z-index: 100001;
    background-color: v-bind(clr);
  }

  #lngSwitcher {
    width: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  #stepBack {
    position: absolute;
    top: 50%;
    left: 1.5rem;
    width: 1.5rem;
    height: 1.5rem;
    z-index: 9999;
    transform: translateX(v-bind(offsideVal)) translateY(-50%);
    transition: transform 0.4s ease-in-out;
  }

  #stepBack.showBack {
    transform: translateX(0) translateY(-50%);
  }

  #stepBack div {
    position: absolute;
    width: 100%;
    height: 3px;
    background: white;
    transition: all 0.4s ease-in-out;
  }

  #stepBack div:first-child {
    top: 50%;
    transform: translateY(-45%) translateY(-0.3rem) translateX(-0.42rem)
      rotate(-45deg) scaleX(0.5);
  }

  #stepBack div:nth-child(2) {
    top: 50%;
    transform: translateY(-50%);
  }

  #stepBack div:last-child {
    bottom: 50% !important;
    transform: translateY(45%) translateY(0.3rem) translateX(-0.42rem)
      rotate(45deg) scaleX(0.5);
  }

  #navLogo {
    position: absolute;
    top: 50%;
    left: 1.5rem;
    /*width: 1.5rem;
        height: 1.5rem;*/
    z-index: 9999;
    transition: all 0.1s ease-in-out;
    transform: translateY(-50%);
  }

  #navLogo.openedLogo {
    transform: scale(0);
  }

  #navLogo.openedLogo #logoWrapper {
    transform: scale(1.3);
  }

  #hamburger {
    position: absolute;
    top: 50%;
    right: 1.5rem;
    width: 1.5rem;
    height: 1.5rem;
    z-index: 9999;
    transition: transform 0.1s ease-in-out;
    transform: translateY(-50%);
  }

  #hamburger.openedBurger div {
    background-color: white;
  }

  #hamburger div {
    position: absolute;
    width: 100%;
    height: 3px;
    transition: all 0.4s ease-in-out;
    background-color: v-bind(clr);
  }

  #hamburger div:first-child {
    top: 0;
  }

  #hamburger div:nth-child(2) {
    top: 50%;
    transform: translateY(-50%);
  }

  #hamburger div:last-child {
    bottom: 0;
  }

  #hamburger.openedBurger div:first-child {
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0) rotate(-45deg);
  }

  #hamburger.openedBurger div:nth-child(2) {
    top: 50%;
    transform: translateY(-50%);
    opacity: 0;
  }

  #hamburger.openedBurger div:last-child {
    bottom: 50% !important;
    left: 50%;
    transform: translate3d(-50%, 50%, 0) rotate(45deg);
  }

  .mobileNav #lngSwitcher {
    width: 100%;
    height: 10%;
  }
}
</style>
